import API from './API';
import { loginActions } from './../store/login-slice';


const setupInterceptors = (store) => {
    API.interceptors.request.use(
        config => {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    API.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            let res = error.response;
            console.log(error)
            if (res.status === 401) {
                //

                localStorage.removeItem("access_token")
                store.dispatch(loginActions.setLogout())
            }

            if (res.status === 500) {
                localStorage.removeItem("access_token")
                store.dispatch(loginActions.setLogout())
            }
            console.error("Something went wrong in response code" + res.status);
            return Promise.reject(error);
        }
    );
}

export default setupInterceptors;
