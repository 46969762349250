export const API_URL = 'https://stt-user-api.acuizen.com';
export const USER_API_URL = 'https://stt-user-api.acuizen.com';
export const MAIN_API_URL = 'https://stt-api.acuizen.com';
export const CURRENT_USER_URL = API_URL + '/users/me';
export const INCIDENT_REVIWERER_URL = 'https://stt-api.acuizen.com/users/incident-reviewer';
// export const API_URL = 'http://localhost:3060';
// export const USER_API_URL = 'http://localhost:3060';
export const LOCATION1_URL = API_URL + '/location-ones';
export const LOCATION2_URL = API_URL + '/location-twos';
export const LOCATION3_URL = API_URL + '/location-threes';
export const LOCATION4_URL = API_URL + '/location-fours';
export const LOCATIONS_URL = API_URL + '/location-ones';
export const SUBMIT_URL = API_URL + '/location-ones';
export const CHECKLIST_URL = API_URL + '/location-ones';
export const DOCUMENTS_URL = API_URL + '/location-ones';
export const LOCATION_CONFIG_URL = API_URL + '/location-ones';
export const STATIC_URL = USER_API_URL + '/docs';
export const USERS_URL = API_URL + '/users';
export const INTERNAL_USERS_URL = API_URL + '/internal/users';
export const EXTERNAL_USERS_URL = API_URL + '/users/external';
export const DYNAMIC_TITLES_URL = API_URL + '/dynamic-titles';
export const OBSERVATION_REPORT_URL = USER_API_URL + '/observation-reports';
export const REPORT_INCIDENT_URL = USER_API_URL + '/report-incidents';
export const REPORT_INCIDENT_REVIEW_URL = USER_API_URL + '/report-incidents-review';
export const REPORT_INCIDENT_TRIGGER_URL = USER_API_URL + '/report-incidents-trigger';
export const LEAD_INVESTIGATOR_URL = USER_API_URL + '/lead-investigator-list';
export const ACTION_URL = API_URL + '/actions';
export const FILE_URL = API_URL + '/files';
export const OBSERVATION_REPORT_BY_OTHERS_URL = API_URL + '/observation-reports-by-locations';
export const ALL_USERS_BY_LOCATION = API_URL + '/all-users-by-location';

export const ALL_INCIDENT_URL = API_URL + '/all-report-incidents';
export const ALL_PERMITS_URL = API_URL + '/all-permit-reports';



export const PERMIT_REPORTS = API_URL + '/permit-reports';
export const PERMIT_REPORT_WITH_ID = (id) => {
    return API_URL + '/permit-reports/' + id;
}


export const OBSERVATION_REPORT_WITH_ID = (id) => {
    return API_URL + '/observation-reports/' + id;
}



export const REPORT_INCIDENT_URL_WITH_ID = (id) => {
    return API_URL + '/report-incidents/' + id;
}




export const SAVE_REPORT_INCIDENT_URL_WITH_ID = (id) => {
    return API_URL + '/save-report-incidents/' + id;
}

export const REPORT_INCIDENT_REVIEW_URL_WITH_ID = (id) => {
    return API_URL + '/report-incidents-review/' + id;
}

export const DYNAMIC_TITLES_WITH_ID = (id) => {
    return API_URL + '/dynamic-titles/' + id;
}

export const USERS_URL_WITH_ID = (id) => {
    return API_URL + '/users/' + id;
}

export const LOCATION1_WITH_ID_URL = (id) => {
    return API_URL + '/location-ones/' + id;
}

export const GMS1_URL = API_URL + '/ghs-ones';

export const GMS1_WITH_ID_URL = (id) => {
    return API_URL + '/ghs-ones/' + id;
}


export const WORK_ACTIVITIES_URL = API_URL + '/work-activities';

export const WORK_ACTIVITIES_WITH_ID_URL = (id) => {
    return API_URL + '/work-activities/' + id;
}


export const RISK_CATEGORIES_URL = API_URL + '/risk-categories';

export const RISK_CATEGORIES_WITH_ID_URL = (id) => {
    return API_URL + '/risk-categories/' + id;
}

export const SURFACE_TYPES_URL = API_URL + '/surface-types';

export const SURFACE_TYPES_WITH_ID_URL = (id) => {
    return API_URL + '/surface-types/' + id;
}

export const SURFACE_CONDITIONS_URL = API_URL + '/surface-conditions';

export const SURFACE_CONDITIONS_WITH_ID_URL = (id) => {
    return API_URL + '/surface-conditions/' + id;
}


export const EHS_ROLE_URL = API_URL + '/ehs-roles';

export const EHS_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/ehs-roles/' + id;
}


export const EPTW_ROLE_URL = API_URL + '/eptw-roles';

export const EPTW_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/eptw-roles/' + id;
}

export const INCIDENT_ROLE_URL = API_URL + '/incident-roles';

export const INCIDENT_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/incident-roles/' + id;
}

export const INSPECTION_ROLE_URL = API_URL + '/inspection-roles';

export const INSPECTION_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/inspection-roles/' + id;
}

export const PLANT_ROLE_URL = API_URL + '/plant-roles';

export const PLANT_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/plant-roles/' + id;
}


export const GMS1_GMS2_URL = (id) => {
    return API_URL + '/ghs-ones/' + id + '/ghs-twos';
}

export const INCIDENT_CATEGORY_URL = MAIN_API_URL + '/incident-circumstance-categories';

export const INCIDENT_CATEGORY_TYPE_URL = (id) => {
    return MAIN_API_URL + '/incident-circumstance-categories/' + id + '/incident-circumstance-types';
}



export const INCIDENT_TYPE_DESCRIPTION_URL = (id) => {
    return MAIN_API_URL + '/incident-circumstance-types/' + id + '/incident-circumstance-descriptions';
}


export const GMS2_WITH_ID_URL = (id) => {
    return API_URL + '/ghs-twos/' + id;
}


export const LOCATION_TIER1_URL = (id) => {
    return API_URL + '/location-ones/' + id + '/location-twos';
}

export const TIER1_TIER2_URL = (id) => {
    return API_URL + '/location-twos/' + id + '/location-threes';
}

export const TIER2_TIER3_URL = (id) => {
    return API_URL + '/location-threes/' + id + '/location-fours';
}

export const TIER3_TIER4_URL = (id) => {
    return API_URL + '/location-fours/' + id + '/location-fives';
}
export const TIER4_TIER5_URL = (id) => {
    return API_URL + '/location-fives/' + id + '/location-sixes';
}

export const EDIT_GMS_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/ghs-ones/' + id;
            break;
        case 'tier2':

            return API_URL + '/ghs-twos/' + id;
            break;

        default: break;
    }
}

export const EDIT_TIER_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/location-twos/' + id;
            break;
        case 'tier2':

            return API_URL + '/location-threes/' + id;
            break;
        case 'tier3':

            return API_URL + '/location-fours/' + id;
            break;
        case 'tier4':

            return API_URL + '/location-fives/' + id;
            break;

        case 'tier5':

            return API_URL + '/location-sixes/' + id;
            break;

        default: break;
    }
}

