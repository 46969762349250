import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`incident-tabpanel-${index}`}
            aria-labelledby={`incident-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `incident-tab-${index}`,
        'aria-controls': `incident-tabpanel-${index}`,
    };
}

const ReportModal = () => {

    const [value, setValue] = useState(0);
    const [topLevelValue, setTopLevelValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleTopLevelChange = (event, newValue) => {
        setTopLevelValue(newValue);
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={topLevelValue} onChange={handleTopLevelChange} aria-label="observation report table">
                        <Tab label="Daily" {...a11yProps(0)} />
                        <Tab label="Monthly" {...a11yProps(1)} />

                    </Tabs>
                </Box>
                <CustomTabPanel value={topLevelValue} index={0}>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="countrySelect" className="form-label">Country *</label>
                            <select className="form-select" id="countrySelect">
                                <option selected>Select</option>
                                {/* Add other options here */}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="citySelect" className="form-label">City *</label>
                            <select className="form-select" id="citySelect">
                                <option selected>Select</option>
                                {/* Add other options here */}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="businessUnitSelect" className="form-label">Business Unit *</label>
                            <select className="form-select" id="businessUnitSelect">
                                <option selected>Select</option>
                                {/* Add other options here */}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="projectSelect" className="form-label">Project/DC Name *</label>
                            <select className="form-select" id="projectSelect">
                                <option selected>Select</option>
                                {/* Add other options here */}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="dateInput" className="form-label">Date *</label>
                            <input type="date" className="form-control" id="dateInput" value="2023-10-09" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="sttEmployeesInput" className="form-label">Number of STT GDC Employees on-site *</label>
                            <input type="number" className="form-control" id="sttEmployeesInput" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="dailyHoursInput" className="form-label">Daily hours worked of STT GDC employee *</label>
                            <input type="number" className="form-control" id="dailyHoursInput" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="contractorEmployeesInput" className="form-label">Number of Contractor Employees on-site (incl. GC/Main Contractor, Sub-contractors, etc.) *</label>
                            <input type="number" className="form-control" id="contractorEmployeesInput" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="dailyHoursContractorInput" className="form-label">Daily hours worked of contractor employee *</label>
                            <input type="number" className="form-control" id="dailyHoursContractorInput" />
                        </div>


                    </form>
                </CustomTabPanel>
                <CustomTabPanel value={topLevelValue} index={1}>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="countrySelect" className="form-label">Country *</label>
                            <select className="form-select" id="countrySelect">
                                <option selected>Select</option>
                                {/* Add other options here */}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="citySelect" className="form-label">City *</label>
                            <select className="form-select" id="citySelect">
                                <option selected>Select</option>
                                {/* Add other options here */}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="businessUnitSelect" className="form-label">Business Unit *</label>
                            <select className="form-select" id="businessUnitSelect">
                                <option selected>Select</option>
                                {/* Add other options here */}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="projectSelect" className="form-label">Project/DC Name *</label>
                            <select className="form-select" id="projectSelect">
                                <option selected>Select</option>
                                {/* Add other options here */}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="dateInput" className="form-label">Year and Month *</label>
                            <input type="month" className="form-control" id="dateInput" value="2023-10" />
                        </div>

                        {/* Below are the safety related fields */}
                        <div className="mb-3">
                            <label htmlFor="safetyInductionsInput" className="form-label">No. of Safety Inductions Conducted *</label>
                            <input type="number" className="form-control" id="safetyInductionsInput" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="toolboxMeetingsInput" className="form-label">No. of Toolbox Meetings/Safety Briefings/Safe Starts</label>
                            <input type="number" className="form-control" id="toolboxMeetingsInput" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ehsTrainingsInput" className="form-label">No. of EHS Trainings *</label>
                            <input type="number" className="form-control" id="ehsTrainingsInput" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ehsInspectionsInput" className="form-label">No. of EHS Inspections/Audits *</label>
                            <input type="number" className="form-control" id="ehsInspectionsInput" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="managementSiteWalkInput" className="form-label">No. of Management Site Walk/Inspection *</label>
                            <input type="number" className="form-control" id="managementSiteWalkInput" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="authorityVisitsInput" className="form-label">No. of Authority/NGO/Union Visits *</label>
                            <input type="number" className="form-control" id="authorityVisitsInput" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="safeObservationsInput" className="form-label">No. of Safe Observations *</label>
                            <input type="number" className="form-control" id="safeObservationsInput" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="atRiskObservationsInput" className="form-label">No. of At-Risk Observations *</label>
                            <input type="number" className="form-control" id="atRiskObservationsInput" />
                        </div>

                    </form>
                </CustomTabPanel>

            </Box>
        </>
    )
}

export default ReportModal;
