import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import store from './app/store/index'
import setupInterceptors from './app/services/setupInterceptors';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <BrowserRouter>
  <Provider store={store}>
 
   <App />
 </Provider>
</BrowserRouter>);

setupInterceptors(store)
serviceWorker.unregister();